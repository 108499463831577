import request from '@/utils/request';

export function List(params) {
    return request({
        url: '/admin/collocation/dctionaries/page',
        method: 'GET',
        params
    });
}

export function Save(data) {
    return request({
        url: '/admin/collocation/dctionaries/save',
        method: 'POST',
        data
    });
}

export function Update(data) {
    return request({
        url: '/admin/collocation/dctionaries/update',
        method: 'POST',
        data
    });
}

export function Delete(params) {
    return request({
        url: '/admin/collocation/dctionaries/delete',
        method: 'GET',
        params
    });
}

export function Detaile(params) {
    return request({
        url: '/admin/collocation/dctionaries/detail',
        method: 'GET',
        params
    });
}
