<template>
    <div class="page-cu-container">
        <div class="page-cu-top">
            <a-form layout="inline">
                <a-form-item>
                    <a-button icon="plus-circle" type="primary" @click="openAddModel">添加字典</a-button>
                </a-form-item>
            </a-form>
        </div>
        <div class="page-cu-main">
            <a-table bordered :columns="columns" :data-source="list" rowKey="id" :pagination="false">
                    <span slot="action" slot-scope="text, record">
                        <a @click="openEditDialog(record['id'])"><a-icon type="edit" /> 编辑</a>
                        <a-divider type="vertical" />
                        <a href="javascript:;" class="text-red" @click="del(record['id'])"><a-icon type="close"  />删除</a>
                    </span>
            </a-table>
        </div>
        <div class="page-cu-pagination">
            <a-pagination :page-size.sync="page.pageSize" :total="page.totalRow" v-model="page.start" @change="changePage" />
        </div>
        <a-modal title="数据字典管理" :visible="actionVisible" @ok="actionData" @cancel="closeModel" ok-text="确认" cancel-text="取消">
            <a-form-model ref="formModel" :model="formModel" :rules="formModelRules" layout="vertical" :label-col="{ span: 4 }" :wrapper-col="{ span: 14 }">
                <a-form-model-item label="字段名称" prop="sys_name">
                    <a-input v-model="formModel.sys_name" placeholder="请输入字段名称"></a-input>
                </a-form-model-item>
                <a-form-model-item label="字段值" prop="sys_value">
                    <a-input v-model="formModel.sys_value" placeholder="请输入字段值"></a-input>
                </a-form-model-item>
                <a-form-model-item label="字段备注" prop="sys_remark">
                    <a-textarea v-model="formModel.sys_remark" placeholder="请输入字段备注" :rows="2"></a-textarea>
                </a-form-model-item>
            </a-form-model>
        </a-modal>
    </div>

</template>

<script>
    import * as Api from './api'

    export default {
        name: "index",
        data(){
            return{
                columns: [
                    {
                        title: '字段名称',
                        dataIndex: 'sys_name',
                    },
                    {
                        title: '字段值',
                        dataIndex: 'sys_value',
                        width: 300
                    },
                    {
                        title: '字段备注',
                        dataIndex: 'sys_remark',
                        width: 300
                    },
                    {
                        title: '操作',
                        width: 200,
                        scopedSlots: { customRender: 'action' },
                    },
                ],
                page:{
                    start: 1,
                    totalRow: 0,
                    pageSize: 0,
                    limit: 20,
                },
                list:[],
                actionVisible: false,
                formModel: {
                    sys_name: '',
                    sys_value: '',
                    sys_remark: '',
                },
                formModelRules: {
                    sys_name: [{ required: true, message: '请输入字段名称', trigger: 'change' }],
                    sys_value: [{ required: true, message: '请输入字段值', trigger: 'change' }],
                },
                submitFlag: true
            }
        },
        mounted() {
            this.getList();
        },
        methods: {
            // 打开添加框
            openAddModel() {
                this.resetFormModel();
                this.actionVisible = true;
            },

            // 关闭框
            closeModel() {
                this.actionVisible = false;
            },

            // 添加，更新
            actionData() {
                if (this.submitFlag) {
                    this.$refs.formModel.validate(async valid => {
                        if (valid) {
                            let res = null;
                            this.submitFlag = false;
                            if (this.formModel['id']) {
                                res = await Api.Update(this.formModel);
                            } else {
                                res = await Api.Save(this.formModel);
                            }
                            if (res && res['code'] == '0') {
                                this.$message.success(res.message);
                                this.actionVisible = false;
                                this.getList();
                            } else {
                                this.$message.error(res.message);
                            }

                            this.submitFlag = true;
                        }
                    });
                }
            },
            // 重置表单
            resetFormModel() {
                for( let key in this.formModel) {
                    this.formModel[key] = '';
                }
            },
            // 删除
            async del(id) {
                let that = this;
                this.$confirm({
                    title: '信息提示',
                    okType: 'danger',
                    okText: '确定',
                    cancelText: '取消',
                    content: '您确定要删除吗?',
                    async onOk() {
                        let res = await Api.Delete({ id: id })
                        if( res && res.code == '0'){
                            that.$message.success(res.message);
                            that.getList();
                        } else {
                            that.$message.error(res.message);
                        }
                    },
                    onCancel() {},
                });
            },

            // 列表
            async getList(){
                let res = await Api.List(this.page)
                this.page.totalRow = res.page.totalRow;
                this.page.pageSize = res.page.pageSize;
                this.list = res.page.list;
            },
            // 切换分页
            changePage(start) {
                this.page.start = start;
                this.getList();
            },
            // 打开编辑框
            async openEditDialog(id) {
                this.resetFormModel()
                if (this.submitFlag) {
                    this.submitFlag = false;
                    let res = await Api.Detaile({id: id});
                    if ( res && res['code'] == '0') {
                        this.formModel = Object.assign({}, res['dctionaries']);
                        this.actionVisible = true;
                    }
                    this.$message.success(res.message);
                    this.submitFlag = true;

                }
            },
        },
    }
</script>

<style scoped>

</style>
